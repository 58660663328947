exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-apply-documents-js": () => import("./../../../src/pages/apply/documents.js" /* webpackChunkName: "component---src-pages-apply-documents-js" */),
  "component---src-pages-apply-form-js": () => import("./../../../src/pages/apply/form.js" /* webpackChunkName: "component---src-pages-apply-form-js" */),
  "component---src-pages-blogs-blog-js": () => import("./../../../src/pages/blogs/blog.js" /* webpackChunkName: "component---src-pages-blogs-blog-js" */),
  "component---src-pages-blogs-blogs-js": () => import("./../../../src/pages/blogs/blogs.js" /* webpackChunkName: "component---src-pages-blogs-blogs-js" */),
  "component---src-pages-categories-loans-for-career-enhancement-js": () => import("./../../../src/pages/categories/loans-for-career-enhancement.js" /* webpackChunkName: "component---src-pages-categories-loans-for-career-enhancement-js" */),
  "component---src-pages-categories-loans-for-cosmetic-medical-treatments-js": () => import("./../../../src/pages/categories/loans-for-cosmetic-medical-treatments.js" /* webpackChunkName: "component---src-pages-categories-loans-for-cosmetic-medical-treatments-js" */),
  "component---src-pages-categories-loans-for-education-js": () => import("./../../../src/pages/categories/loans-for-education.js" /* webpackChunkName: "component---src-pages-categories-loans-for-education-js" */),
  "component---src-pages-categories-loans-for-health-fitness-js": () => import("./../../../src/pages/categories/loans-for-health-fitness.js" /* webpackChunkName: "component---src-pages-categories-loans-for-health-fitness-js" */),
  "component---src-pages-categories-loans-for-self-growth-js": () => import("./../../../src/pages/categories/loans-for-self-growth.js" /* webpackChunkName: "component---src-pages-categories-loans-for-self-growth-js" */),
  "component---src-pages-categories-loans-for-small-business-js": () => import("./../../../src/pages/categories/loans-for-small-business.js" /* webpackChunkName: "component---src-pages-categories-loans-for-small-business-js" */),
  "component---src-pages-categories-loans-for-travelling-js": () => import("./../../../src/pages/categories/loans-for-travelling.js" /* webpackChunkName: "component---src-pages-categories-loans-for-travelling-js" */),
  "component---src-pages-categories-loans-to-nurture-talent-js": () => import("./../../../src/pages/categories/loans-to-nurture-talent.js" /* webpackChunkName: "component---src-pages-categories-loans-to-nurture-talent-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-display-cat-arts-js": () => import("./../../../src/pages/display/cat/arts.js" /* webpackChunkName: "component---src-pages-display-cat-arts-js" */),
  "component---src-pages-display-cat-business-js": () => import("./../../../src/pages/display/cat/business.js" /* webpackChunkName: "component---src-pages-display-cat-business-js" */),
  "component---src-pages-display-cat-education-js": () => import("./../../../src/pages/display/cat/education.js" /* webpackChunkName: "component---src-pages-display-cat-education-js" */),
  "component---src-pages-display-cat-fitness-js": () => import("./../../../src/pages/display/cat/fitness.js" /* webpackChunkName: "component---src-pages-display-cat-fitness-js" */),
  "component---src-pages-display-cat-grooming-js": () => import("./../../../src/pages/display/cat/grooming.js" /* webpackChunkName: "component---src-pages-display-cat-grooming-js" */),
  "component---src-pages-display-cat-other-js": () => import("./../../../src/pages/display/cat/other.js" /* webpackChunkName: "component---src-pages-display-cat-other-js" */),
  "component---src-pages-display-cat-skilling-js": () => import("./../../../src/pages/display/cat/skilling.js" /* webpackChunkName: "component---src-pages-display-cat-skilling-js" */),
  "component---src-pages-display-cat-travel-js": () => import("./../../../src/pages/display/cat/travel.js" /* webpackChunkName: "component---src-pages-display-cat-travel-js" */),
  "component---src-pages-display-consent-js": () => import("./../../../src/pages/display/consent.js" /* webpackChunkName: "component---src-pages-display-consent-js" */),
  "component---src-pages-display-faq-js": () => import("./../../../src/pages/display/faq.js" /* webpackChunkName: "component---src-pages-display-faq-js" */),
  "component---src-pages-display-grievance-js": () => import("./../../../src/pages/display/grievance.js" /* webpackChunkName: "component---src-pages-display-grievance-js" */),
  "component---src-pages-display-privacy-js": () => import("./../../../src/pages/display/privacy.js" /* webpackChunkName: "component---src-pages-display-privacy-js" */),
  "component---src-pages-display-tnc-js": () => import("./../../../src/pages/display/tnc.js" /* webpackChunkName: "component---src-pages-display-tnc-js" */),
  "component---src-pages-homepage-js": () => import("./../../../src/pages/homepage.js" /* webpackChunkName: "component---src-pages-homepage-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-consent-js": () => import("./../../../src/pages/legal/consent.js" /* webpackChunkName: "component---src-pages-legal-consent-js" */),
  "component---src-pages-legal-frequently-asked-questions-js": () => import("./../../../src/pages/legal/frequently-asked-questions.js" /* webpackChunkName: "component---src-pages-legal-frequently-asked-questions-js" */),
  "component---src-pages-legal-grievance-redressal-policy-js": () => import("./../../../src/pages/legal/grievance-redressal policy.js" /* webpackChunkName: "component---src-pages-legal-grievance-redressal-policy-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-terms-and-conditions-js": () => import("./../../../src/pages/legal/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-legal-terms-and-conditions-js" */),
  "component---src-pages-merchants-js": () => import("./../../../src/pages/merchants.js" /* webpackChunkName: "component---src-pages-merchants-js" */),
  "component---src-pages-partners-nbfc-bank-partner-js": () => import("./../../../src/pages/Partners/NBFCBankPartner.js" /* webpackChunkName: "component---src-pages-partners-nbfc-bank-partner-js" */),
  "component---src-pages-partners-partner-list-js": () => import("./../../../src/pages/Partners/partner_list.js" /* webpackChunkName: "component---src-pages-partners-partner-list-js" */),
  "component---src-pages-product-details-js": () => import("./../../../src/pages/product-details.js" /* webpackChunkName: "component---src-pages-product-details-js" */),
  "component---src-pages-wheretoshop-js": () => import("./../../../src/pages/wheretoshop.js" /* webpackChunkName: "component---src-pages-wheretoshop-js" */),
  "component---src-pages-wheretoshopfull-js": () => import("./../../../src/pages/wheretoshopfull.js" /* webpackChunkName: "component---src-pages-wheretoshopfull-js" */)
}

